<template>
    <div>
        <LocationsNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter main fill">
            <h1>Locations</h1>
            <div v-for="(cdiLocation, index) in cdiLocations" :key="cdiLocation.cdi_location_id">
                <h2>{{cdiLocation.cdi_location}}</h2>
                <b>CDI</b><br>
                {{cdiLocation.cdi_street}}<br>
                {{cdiLocation.cdi_city}}, {{cdiLocation.cdi_state}} {{cdiLocation.cdi_zip}}<br>
                Phone: 1.888.234.7001 <br>
                Local: {{cdiLocation.cdi_phone}}

                <div class="spacing_top spacing_btm">
                    <iframe width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" :src="maps[index]"></iframe>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import LocationsNav from '@/components/content/LocationsNav.vue'
import { store } from '@/store/Tool.store';
export default {
    name: "Locations",
    components: {
        LocationsNav,
    },
    data() {
        return {
            cdiLocations: [],
            maps: [
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2748.910193558382!2d-80.98392043520182!3d34.98107457075118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885685f6fd67bb41%3A0x858e3bb84cdbc970!2s3065+Southcross+Blvd%2C+Rock+Hill%2C+SC+29730!5e0!3m2!1sen!2sus!4v1487339930488",
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.9355182480813!2d-96.99334098481484!3d32.89987308093511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c29eea90e47c1%3A0x78a919a565794527!2s6701+N+Belt+Line+Rd%2C+Irving%2C+TX+75063!5e0!3m2!1sen!2sus!4v1457369065357",
                "https://maps.google.com/maps?ie=UTF8&cid=8762659357235392935&q=Crystal+Distribution&gl=US&hl=en&t=m&ll=45.286482,-93.542061&spn=0.021136,0.036478&z=14&iwloc=&output=embed",
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.9998918187953!2d-112.28005792455068!3d33.449309349326754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b3facdeb2fe45%3A0xf3d71ae3d36cfdef!2s10115%20W%20Van%20Buren%20St%20Building%20C%2C%20Suite%202%2C%20Tolleson%2C%20AZ%2085353!5e0!3m2!1sen!2sus!4v1693410724252!5m2!1sen!2sus",
                "https://maps.google.com/maps?ie=UTF8&cid=10640926445781373173&q=Crystal+Distribution+Inc&gl=US&hl=en&t=m&ll=47.216021, -122.332603&spn=0.040723,0.072956&z=13&iwloc=&output=embed",
            ]
        }
    },
    created() {
        this.$appStore.setTitle(['CDI Locations - Charlotte | Dallas | Minneapolis | Seattle | Phoenix']);
        store.api('/cdi_location/location_addresses')
            .then(results => this.cdiLocations = results);
    },
}
</script>

<style scoped>

</style>