<template>
    <div>
        <nav class="nav_side col-lg-2 col-md-3 col-sm-3">
            <ul class="nav nav-pills nav-stacked affix">
                <li class="cdi_tab"><a :href="`${$cdiVars.BASE_URL}/pdf/cdi_transit_map_2021.pdf`">Transit Map</a></li>
              <li class="cdi_tab"> <router-link :to="`/directory`">Directory</router-link></li>
              <!--             <li class="cdi_tab"><router-link to="/territory">Sales Managers</router-link></li>-->
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
  name: "LocationsNav",
}
</script>

<style scoped>

</style>